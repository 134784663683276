import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import userStore from "@reservauto/react-shared/stores/userStore";
import { User, UserProfile as OidcUserProfile } from "oidc-client-ts";
import { SubscriptionUserDetailDTO } from "../../areas/userManagement/dto";

export interface UserProfile extends OidcUserProfile {
  [claimKey: string]: unknown;
  branchId?: number;
  displayName: string;
  idp: string;
  role: Array<string>;
  ui_locale: string;
  userType: string;
}

export interface UserInfo {
  details: SubscriptionUserDetailDTO | null;
  userProfile: UserProfile | null;
}

export class UserSubscriptionStore extends StateStoreBase<UserInfo> {
  private userInfo: UserInfo = {
    details: null,
    userProfile: null,
  };

  public get(): UserInfo {
    return this.userInfo;
  }

  public populate(authenticatedUser: User): UserProfile {
    const profile = authenticatedUser.profile as UserProfile;

    this.userInfo = {
      ...this.userInfo,
      userProfile: profile,
    };
    userStore.populate({
      accessToken: authenticatedUser.access_token,
      uniqueUserId: `${profile.sub}_${profile.branchId}`,
    });

    this.notifySubscribers();

    return profile;
  }

  public async populateDetails(
    getDetails: () => Promise<SubscriptionUserDetailDTO>,
  ): Promise<void> {
    const details = await getDetails();

    this.userInfo = {
      ...this.userInfo,
      details: details,
    };
    this.notifySubscribers();
  }
}

const userSubscriptionStore = new UserSubscriptionStore();
export default userSubscriptionStore;
